import '../stylesheets/footer.css'

export default function Footer(){


    return(
        <div class='footer'>
            <div>
                <p>Address: 71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ</p>
                <p>Contact: info@sjdevelopment.co.uk</p>
                <p>© All rights reserved</p>
            </div>
            <div class='footer-social-icons'>

            </div>
        </div>
    )
}