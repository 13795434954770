import '../stylesheets/reviews.css'
import { FaStar } from 'react-icons/fa'
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import bigBannerImage from '../images/Rectangle 8.png';
import notiforce from '../images/hGd8oLKs.png'
import amyrowland from '../images/amyiswlogo.png'

export default function Reviews() {

    var items = [
        {
            name: "Joe Drage",
            message: '"SJ Development have been integral in moving our business from an idea to reality. From the very start Jake and Sam offered constant advice and guidance, brought ideas to the table and have continued to work quickly to implement features which are helping to boost our growth. We now have a monthly support package with them to keep developing new areas of our business. We couldn’t recommend them more!"',
            rating: <span><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></span>,
            company: "Co-Owner Of Notiforce",
            image: notiforce
        },
        {
            name: "Amy Rowland",
            message: '"SJ Development managed the design and implementation of my website and I couldn’t recommend them anymore! "',
            rating: <span><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></span>,
            company: "",
            image: amyrowland
        },


    ]
    return (
        
            <Carousel
                next={(next, active) => console.log(`we left ${active}, and are now at ${next}, ${items.length}`)}
                prev={(prev, active) => console.log(`we left ${active}, and are now at ${prev}`)}
                autoPlay={false}
                navButtonsAlwaysVisible={items.length == 1 ? false : true}
                indicators={items.length == 1 ? false : true}
                className='carousel'
            >
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>

    )
}

function Item(props) {
    return (
        <div className='review-container' id='reviews'>
            <div>
                <img src={props.item.image} className='customer-logo' />
            </div>
            <div>
                <h3>What Our Clients Said</h3>
                <p>{props.item.message}</p>
                <div>
                    <p>{props.item.name}</p>
                    <p>{props.item.company}</p>
                    <p>{props.item.rating}</p>
                </div>
            </div>
        </div>
    )
}