
import image from '../images/Rectangle 7.png'
import { FaMobile, FaServer, FaDatabase } from 'react-icons/fa';

import '../stylesheets/services.css'

export default function Services() {
    return (
        <div class="service-container" id='services'>
            <div>
                <h3>Our Services</h3>
                <p>We offer a wide range of servives to fit every business need. In need of a service not listed here? Feel free to contact us!</p>
                <img src={image} />
            </div>

            <div class="services">
                <div class="service-box">
                    <div>
                        <FaMobile size={50} />
                    </div>
                    <div>
                        <h5>Mobile and Web Applications</h5>
                        <p>Got an idea for the latest Social Media? Or Candy Crush 2.0? Our full stack developers can bring that to life.</p>
                    </div>
                </div>

                <div class="service-box">
                    <div>
                        <FaDatabase size={50} />
                    </div>
                    <div>
                        <h5>Enterprise Infrastructure</h5>
                        <p>Need an highly scalable server infrastructure with 99% guaranteed uptime? We've got you covered!</p>
                    </div>
                </div>

                <div class="service-box">
                    <div>
                        <FaServer size={50} />
                    </div>
                    <div>
                        <h5>Web Design and Hosting</h5>
                        <p>Fast and reliable web hosting with free SSL and business email included to make your business stand out.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}