import './App.css';

import Header from './components/Header';
import Banner from './components/Banner';
import Services from './components/Services';
import Reviews from './components/Reviews';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';


function App() {
  return (
    <>
    <Header />
      
    <div class="container"> 
      <Banner/>
      <Services/>
      <Reviews/>
    </div>
    <Contact/>

    <div class="container">
      <Footer/>
    </div>

    </>
  );
}

export default App;
