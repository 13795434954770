import logo from '../images/Logo-01.png'
import '../stylesheets/header.css'

export default function Header() {
  return (
    <header>
      <div>
        <img src={logo} class="logo-image" />
      </div>
      <div>
        <ul class="navigation">
          <li><a href='#reviews'>Work</a></li>
          <li><a href='#services'>Our Services</a></li>
          <li><a href='#contact-link'>Contact Us</a></li>
        </ul>
      </div>
    </header>
  )
}