import '../stylesheets/contact.css'
import image from '../images/Rectangle 19.png'
import arrowimage from '../images/Group 11.png'
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';
export default function Contact() {
    const [status, setStatus] = useState(undefined);
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_x7vycf3', 'template_grsr31o', form.current, 'JD7IlHJXRs-xRt1vs')
            .then((result) => {
                console.log(result.text);
                setStatus({ type: 'success' });
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <div className='contact-container' id='contact-link'>
            <div>
                <h1>Contact Us</h1>
                <p>Ready to start a discussion? Use our contact us form and we will get back to you in 1-2 business days.</p>
                <img src={image} />
            </div>
            <div>
                <form ref={form} onSubmit={sendEmail}>
                    <input placeholder='Name' name='name' required />
                    <input placeholder='Phone Number' name='phone' required type="tel" />
                    <input placeholder='Email' name='email' required type="email" />
                    <textarea placeholder='Project Details' name='details' required />

                    {status?.type === 'success' && <p>Message successfully sent!</p>}
                    {status?.type === 'error' && (
                        <p>An Error occured, please email us at info@sjdevelopment.co.uk</p>
                    )}
                    <div>
                        <button type='submit' className='submit-button'><img src={arrowimage} /></button>
                    </div>
                </form>

            </div>
        </div>
    )
}

