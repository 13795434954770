import '../stylesheets/banner.css'
import smallBannerImage from '../images/Rectangle 1.png';
import bigBannerImage from '../images/Rectangle 2.png';
import BannerArrow from '../images/Frame.png'

export default function Banner(){

    return (
        <div class="banner">
        <h1>The Future Is Here</h1>
        <div class="grid-container">
          <div>            
            <p>From planning and designs to testing and integrating our focus to to bring the most of your business. Looking for expert software developers to bring your idea to life? You have come to the right place!</p>
            <button><a href="#contact-link">Work With Us</a></button>
            <a href="#contact-link"><img src={BannerArrow} class="banner-arrow"/></a>
          </div>
          <div>
            <img src={bigBannerImage}></img>
          </div>
          <div>
            <div>
              <p>We have experience in developing frontend applications, backend and api systems as well hosting and maintaining infrastructure for big enterprises.</p>
              <img src={smallBannerImage}></img>
            </div>
          </div>
        </div>
      </div>
    )
}